import store from "@/store";
import {
  ASSESSMENT_QUESTIONS,
  CHECK_TENANT_EXISTENCE,
  REGISTER_TENANT
} from "@/store/modules/candidates/constants";
import { clean_string } from "@/utils/global";
interface InterviewReportIcons {
  icon: string;
  key: string;
  bg_color: string;
  color: string;
}
const INTERVIEW_REPORT_ICONS: InterviewReportIcons[] = [
  {
    icon: require("@/assets/icons/assessments/briefcase.svg"),
    key: "Relevant Educational Background",
    bg_color: "#E9FFF2",
    color: "#27D86D"
  },
  {
    icon: require("@/assets/icons/assessments/teacher.svg"),
    key: "Relevant Work Experience",
    bg_color: "#FCEFFD",
    color: "#EE49FD"
  },
  {
    icon: require("@/assets/icons/assessments/smileys.svg"),
    key: "Communication Skills",
    bg_color: "#FFF1EF",
    color: "#FF8473"
  },
  {
    icon: require("@/assets/icons/assessments/profile-2user.svg"),
    key: "Suitability For The Job",
    bg_color: "#FFF7E5",
    color: "#FFB815"
  },
  {
    icon: require("@/assets/icons/assessments/24-support.svg"),
    key: "Personality",
    bg_color: "#EEFAFF",
    color: "#33BFFF"
  },
  {
    icon: require("@/assets/icons/assessments/frame.svg"),
    key: "Career Goals",
    bg_color: "#FCEFFD",
    color: "#EE49FD"
  },
  {
    icon: require("@/assets/icons/assessments/message-question.svg"),
    key: "Problem Solving",
    bg_color: "#FFF1EF",
    color: "#FF8473"
  },
  {
    icon: require("@/assets/icons/assessments/ghost.svg"),
    key: "Strategic Thinking",
    bg_color: "#FFF7E5",
    color: "#FFB815"
  },
  {
    icon: require("@/assets/icons/assessments/story.svg"),
    key: "Relevant Skill Set",
    bg_color: "#EEFAFF",
    color: "#33BFFF"
  }
];
export async function process_tenant_registration(): Promise<void> {
  // Check tenant existence
  const tenant_exist = await store.dispatch(
    `candidate/${CHECK_TENANT_EXISTENCE}`,
    {}
  );
  // If tenant not exist => register new tenant
  if (!tenant_exist)
    await store.dispatch(`candidate/${REGISTER_TENANT}`, {
      tp_name: "principles"
    });

  // Get questions
  await store.dispatch(`candidate/${ASSESSMENT_QUESTIONS}`, {});
}

export function get_report_icon(key: string): InterviewReportIcons {
  const icon = INTERVIEW_REPORT_ICONS.find(
    (val: InterviewReportIcons) => clean_string(val.key) === clean_string(key)
  );
  if (icon) return icon;
  else return INTERVIEW_REPORT_ICONS[0];
}

export function capitalize_words(string: string): string {
  return string
    .split(" ")
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
