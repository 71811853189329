


























































































































































import Vue from "vue";
import NavSideBar from "@/components/candidate/NavSideBar.vue";
import { process_tenant_registration } from "@/utils/principle_assessments";
import { mapActions, mapGetters } from "vuex";
import {
  APP_ASSESSMENT_ANSWERS,
  APP_ASSESSMENT_QUESTIONNAIRES,
  CHAT_BOT_API
} from "@/store/modules/candidates/constants";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import { APP_ASSESSMENTS } from "@/store/modules/common/constants";
import { Assessment, List } from "@/interfaces/app_assessments";
import {
  AnswerPayload,
  ChatBotPayload,
  ChatBotRoles,
  FetchAssessmentAnswersApiPayload,
  FetchAssessmentQuestionnariesPayload,
  QuestionnairePayload
} from "@/store/modules/candidates/interfaces";
import { wait_until } from "@/utils/global";
import { IApp } from "@/interfaces";

export default Vue.extend({
  name: "CandidateWrapper",
  components: { NavSideBar },
  data() {
    return {
      chat_box_open: false, // Chat bot box open
      message: "" as string, // user message
      loading: false, // loading for question
      bot_content_loading: false, // loading for bot content
      ai_avatar: require("@/assets/images/AIAvatar.jpg"), // AI Avatar
      interview_history: [] as ChatBotPayload[], // Interview history
      assessment_id: 0 as number, // Assessment id
      total: 0 as number, // Total count
      total_questions: 0 as number, // Total questions
      page: 1 as number, // Page
      more_question_extract: false as boolean // Is more question extract
    };
  },
  async mounted() {
    this.bot_content_loading = true; // loading for bot content
    await process_tenant_registration(); // Process tenant registration
    const response = this.get_app_assessments;
    if (response) {
      const chat_bot = response.find((assessment: Assessment) => {
        return assessment.assessment_type === List.CHAT_BOT_MESSAGES;
      });
      if (chat_bot) {
        this.assessment_id = chat_bot.id;
        this.init_chat_bot_history();
      } else {
        this.interview_history.push({
          role: ChatBotRoles.BOT,
          content:
            "Apologies, the chat bot is currently unavailable. Please try again later.",
          picture: this.ai_avatar
        });
      }
    } // Get chat bot
    else {
      this.bot_content_loading = false;
      this.interview_history.push({
        role: ChatBotRoles.BOT,
        content:
          "Apologies, the chat bot is currently unavailable. Please try again later.",
        picture: this.ai_avatar
      });
    }
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    }),
    ...mapGetters("common", {
      get_app_assessments: APP_ASSESSMENTS
    }),
    ChatBotRoles() {
      return ChatBotRoles; // Chat bot roles
    }
  },
  methods: {
    ...mapActions("candidate", {
      chat_bot_api: CHAT_BOT_API,
      get_assessment_question: APP_ASSESSMENT_QUESTIONNAIRES,
      get_assessment_answer: APP_ASSESSMENT_ANSWERS
    }),
    ...mapActions("common", {
      app_assessments: APP_ASSESSMENTS
    }),
    async chat_with_bot() {
      // Chat with bot
      if (this.message.length) {
        this.loading = true; // loading for question
        // Push user message
        this.interview_history.push({
          role: "USER",
          content: this.message,
          picture: ""
        });
        this.scroll_to_bottom();
        // Get bot answer
        const result = await this.chat_bot_api({
          question: this.message,
          portal: IApp.Portals.HCMS,
          conversational: true
        });
        this.message = "";
        // Push bot answer
        if (result) {
          this.loading = false;
          this.interview_history.push({
            role: "BOT",
            content: result.answer,
            picture: this.ai_avatar
          });
        } else {
          this.loading = false;
          // Push bot answer if not found
          this.interview_history.push({
            role: "BOT",
            content: "Sorry, Network error Please try again later.",
            picture: this.ai_avatar
          });
        }
        this.scroll_to_bottom();
      }
    },
    // Scroll to bottom to chat box
    async scroll_to_bottom() {
      // Wait for the refs
      await wait_until(100);
      // Scroll to bottom
      const scroll = this.$refs.scroll as HTMLElement;
      if (scroll) scroll.scrollTop = scroll.scrollHeight;
    },
    async init_chat_bot_history() {
      // Fetch assessment question
      const payload: FetchAssessmentQuestionnariesPayload = {
        assessment_id: this.assessment_id,
        page: this.page - 1,
        limit: 10,
        user_id: this.get_user_details.id,
        order: "desc"
      };
      const question = await this.get_assessment_question(payload);
      // If History found
      if (question?.results) {
        const question_ids = question.results.map(
          (question: QuestionnairePayload) => question.id
        );
        this.total = question.total;
        this.total_questions += question.results.length;
        // Fetch assessment answer
        const answer: FetchAssessmentAnswersApiPayload = {
          assessment_id: this.assessment_id,
          page: 0,
          limit: 10,
          user_id: this.get_user_details.id,
          question_ids: question_ids
        };
        const answers = await this.get_assessment_answer(answer);
        if (answers?.results) {
          // Reverse the array for the first time to show the latest question
          question.results = !this.more_question_extract
            ? question.results.reverse()
            : question.results;
          answers.results = !this.more_question_extract
            ? answers.results.reverse()
            : answers.results;

          question.results.map((question: QuestionnairePayload) => {
            const matched_result = answers.results.find(
              (answer: AnswerPayload) => answer.question_id === question.id
            );

            if (matched_result) {
              if (this.more_question_extract) {
                // If extracting user by clicking load_more to add on top
                this.interview_history.unshift({
                  role: ChatBotRoles.BOT,
                  content: matched_result.answer,
                  picture: this.ai_avatar
                });
                this.interview_history.unshift({
                  role: ChatBotRoles.USER,
                  content: question.question_text,
                  picture: ""
                });
              } else {
                this.interview_history.push({
                  role: ChatBotRoles.USER,
                  content: question.question_text,
                  picture: ""
                });
                this.interview_history.push({
                  role: ChatBotRoles.BOT,
                  content: matched_result.answer,
                  picture: this.ai_avatar
                });
              }
            }
          });
        }
        if (this.interview_history.length === 0) {
          this.interview_history.push({
            role: ChatBotRoles.BOT,
            content: "Hello, I am your AI bot. How can i help you?",
            picture: require("@/assets/images/AIAvatar.jpg")
          });
        }
        this.bot_content_loading = false; // loading for bot content
        if (!this.more_question_extract) this.scroll_to_bottom(); // Scroll to bottom
        this.more_question_extract = true;
      }
    },
    load_more() {
      this.bot_content_loading = true;
      this.page++;
      this.init_chat_bot_history();
    },
    handle_enter(event: KeyboardEvent) {
      if (event.shiftKey) return;
      this.chat_with_bot();
    }
  }
});
