var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"base-card language-box d-flex align-center",attrs:{"items":_vm.language_options,"return-object":"","item-text":"key","item-value":"value","single-line":"","color":"primary","flat":"","solo":"","hide-details":true,"attach":true},on:{"change":_vm.update_locale},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('inline-svg',{class:{
        'mr-2 mr-md-5': _vm.get_site_direction === _vm.SiteDirections.RTL,
        'ms-2 ms-md-5': _vm.get_site_direction === _vm.SiteDirections.LTR
      },attrs:{"src":_vm.dropdown_icon}})]},proxy:true},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"mb-0 base-body-text item"},[_vm._v(_vm._s(item.key))])]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('p',{staticClass:"mb-0 base-body-text item"},[_vm._v(_vm._s(item.key))])]}}]),model:{value:(_vm.current_language),callback:function ($$v) {_vm.current_language=$$v},expression:"current_language"}})}
var staticRenderFns = []

export { render, staticRenderFns }