


































import Vue from "vue";
import { LanguageOptions } from "@/interfaces/shared/change_language";
import { mapMutations, mapGetters } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { LanguageKey, SiteDirections } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "ChangeLanguage",
  data() {
    return {
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg"),
      language_options: [
        {
          key: LanguageKey.ENGLISH.toUpperCase(),
          value: LanguageKey.ENGLISH,
          direction: SiteDirections.LTR
        },
        {
          key: LanguageKey.ARABIC.toUpperCase(),
          value: LanguageKey.ARABIC,
          direction: SiteDirections.RTL
        }
      ] as LanguageOptions[],
      current_language: {} as LanguageOptions
    };
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  created() {
    const locale = localStorage.getItem("locale");
    if (locale) {
      this.current_language = {
        direction:
          locale === LanguageKey.ENGLISH
            ? SiteDirections.LTR
            : SiteDirections.RTL,
        value:
          locale === LanguageKey.ENGLISH
            ? LanguageKey.ENGLISH
            : LanguageKey.ARABIC,
        key:
          locale === LanguageKey.ENGLISH
            ? LanguageKey.ENGLISH.toUpperCase()
            : LanguageKey.ARABIC.toUpperCase()
      };
    } else {
      this.current_language = {
        direction: SiteDirections.LTR,
        value: LanguageKey.ENGLISH,
        key: LanguageKey.ENGLISH.toUpperCase()
      };
    }
  },
  props: {
    extra_padding_class: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations("common", {
      set_site_direction: SITE_DIRECTION
    }),
    update_locale() {
      localStorage.setItem("locale", this.current_language.value);
      this.$i18n.locale = this.current_language.value;
      this.set_site_direction(this.current_language.direction);
    }
  }
});
